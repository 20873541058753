<template>
  <MfiMultiselect
    v-model="selectedRatings"
    :placeholder="$t('shared.rating')"
    option-label="title"
    option-value="value"
    :items="ratingItems"
    :pt="pt"
    :color="props.color"
    @change="onChange"
  />
</template>

<script setup lang="ts">
import MfiMultiselect from '~/components/dropdowns/mfi-multiselect.vue'

const props = defineProps<{ color?: 'dark' | 'gray'; pt?: object }>()

const emit = defineEmits<{
  (e: 'change', event: { value: Array<string>; selectedValue: string }): void
}>()

const { t: $t } = useI18n({
  useScope: 'global'
})

const selectedRatings = defineModel({
  default: []
})

const ratingItems = ref(
  Array.from(Array(7).keys()).map((i) => ({
    title: `(${i + 1}) - ${$t(`shared.ratingNames.${i + 1}`)}`,
    value: i + 1
  }))
)

function onChange(event: { originalEvent: Event; value: Array<number> }) {
  const clickedOn = event.originalEvent.target?.textContent

  emit('change', {
    value: ratingItems.value.filter((r) => event.value.includes(r.value)).map((r) => r.title),
    selectedValue: ratingItems?.value?.find((r) => r.title === clickedOn)?.title as string
  })
}
</script>
